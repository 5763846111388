import Icon from '@mdi/react'
import { mdiArrowDownBoldBox, mdiArrowUpBoldBox } from '@mdi/js'

import useSortTable from 'hooks/useSortTable'

export default function TableHead({ data, headers, columnKeys, cb }) {
  const [filter, sortData] = useSortTable(data)

  const displayTableHeaders = headers.map(
    ({ header, sort, className = '', reverse }, i) => (
      <th
        key={header}
        className={className}
        style={{ cursor: sort ? 'pointer' : 'default' }}
        onClick={() => sort && cb(sortData(columnKeys[i], reverse))}
      >
        {header}
        {filter.field === columnKeys[i] &&
          (filter.up === -1 ? (
            <Icon path={mdiArrowDownBoldBox} size={1} />
          ) : (
            <Icon path={mdiArrowUpBoldBox} size={1} />
          ))}
      </th>
    )
  )
  return (
    <thead>
      <tr>{displayTableHeaders}</tr>
    </thead>
  )
}
