import styled from 'styled-components'
import { ThemeByfi } from './ThemeByfi'

export const MainButton = styled.button`
  display: inline-block;
  text-align: center;
  vertical-align: middle;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  border: 1px solid transparent;
  padding: 12px 32px;
  border-radius: 16px;
  transition: color 0.4s ease-in-out, background-color 0.4s ease-in-out,
    border-color 0.15s ease-in-out, box-shadow 0.3s ease-in-out;
  background-color: ${({ primary, success, danger }) =>
    primary
      ? ThemeByfi.colors.lila
      : success
      ? ThemeByfi.colors.azul
      : danger
      ? ThemeByfi.colors.rojo
      : ThemeByfi.colors.gris_claro} !important;
  color: ${({ primary, success, danger }) =>
    primary ? ThemeByfi.colors.blanco : ThemeByfi.colors.lila} !important;
  font-style: italic;
  font-weight: 700;
  letter-spacing: 0.5px;
  font-size: 18px;
  &:hover {
    text-decoration: none !important;
    box-shadow: 0px 6px 30px rgba(123, 97, 255, 0.15);
  }
  &:focus,
  .focus {
    outline: 0;
    box-shadow: none;
  }
  &:disabled,
  .disabled {
    opacity: 0.65;
    pointer-events: none;
  }
  @media (prefers-reduced-motion: reduce) {
    .btn {
      transition: none;
    }
  }
  @media (max-width: 1100px) {
    padding: 24px 32px;
  }
`

export const ButtonBlock = styled(MainButton)`
  display: block;
  width: 100%;
`

export const OutlineButton = styled(MainButton)`
  color: ${ThemeByfi.colors.lila} !important;
  background-color: transparent !important;
  border: 1px solid ${ThemeByfi.colors.lila} !important;
  &:hover {
    background-color: ${ThemeByfi.colors.lila} !important;
    color: ${ThemeByfi.colors.blanco} !important;
  }
`

export const SquareButton = styled(MainButton)`
  width: 50px;
  height: 50px;
  padding: 0;
  display: flex;
  align-items: center;
  justify-content: center;
`
export const PaginationButtons = styled.div`
  display: flex;
  button {
    width: 32px;
    height: 32px;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-right: 8px;
    border-radius: 6px;
    font-size: 12px;
    color: ${ThemeByfi.colors.lila};
    font-weight: 600;
    background-color: #f2ecff;
  }
  .active {
    background-color: ${ThemeByfi.colors.lila_claro};
    color: white;
  }
`

export const BadgeNewLead = styled.span`
  background-color: ${ThemeByfi.colors.amarillo};
  color: ${ThemeByfi.colors.lila};
  font-size: 11px;
  padding: 3px 6px;
  font-weight: 600;
  border-radius: 20px;
  margin-left: 8px;
`
