import { MainButton } from 'styles/Buttons'
import { Modal } from 'react-bootstrap'

export default function CustomModal({
  show,
  handleClose,
  title,
  children,
  submitAction,
  saveButtonText,
  buttonType = 'submit',
  secBtnText = '',
  secBtnFunction,
  ...rest
}) {
  const btnFunction = secBtnFunction || handleClose
  return (
    <Modal show={show} onHide={handleClose} {...rest}>
      <Modal.Header closeButton>
        <Modal.Title>{title}</Modal.Title>
      </Modal.Header>
      <Modal.Body>{children}</Modal.Body>
      <Modal.Footer className="d-flex flex-row justify-content-around">
        <MainButton danger onClick={btnFunction} type="button">
          {secBtnText || 'Cancelar'}
        </MainButton>
        <MainButton
          success
          form="form"
          type={buttonType}
          onClick={submitAction}
        >
          {saveButtonText || 'Confirmar'}
        </MainButton>
      </Modal.Footer>
    </Modal>
  )
}
