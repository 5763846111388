import { useEffect, useState } from 'react'

import { PaginationButtons } from 'styles/Buttons'

export default function usePagination(data, tableData) {
  const [pagination, setPagination] = useState({
    currentPage: 1,
    itemsPerPage: 10
  })
  const { currentPage, itemsPerPage } = pagination

  useEffect(() => {
    currentPage !== 1 && setPagination({ ...pagination, currentPage: 1 })
  }, [data])

  const indexOfLastRow = currentPage * itemsPerPage
  const indexOfFirstRow = indexOfLastRow - itemsPerPage
  const currentRows = tableData.slice(indexOfFirstRow, indexOfLastRow)

  const handlePageClick = (e) =>
    setPagination({ ...pagination, currentPage: Number(e.target.id) })

  const pageNumbers = new Array(Math.ceil(data.length / itemsPerPage))
    .fill(null)
    .map((_, i) => i + 1)

  const renderPageNumbers = pageNumbers.map((number) => {
    return (
      <button
        key={number}
        id={number}
        onClick={handlePageClick}
        className={currentPage === number ? 'active' : ''}
      >
        {number}
      </button>
    )
  })

  const paginationNode = (
    <div className="ml-2">
      <PaginationButtons>{renderPageNumbers}</PaginationButtons>
      <p className="mt-3">{data.length} Resultados</p>
    </div>
  )

  return [currentRows, paginationNode]
}
