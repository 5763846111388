import { useEffect, useState } from 'react'

import { compare, reverseCompare } from 'utils/table'

const initialFilter = {
  field: '',
  up: 0
}

export default function useSortTable(data) {
  const [filter, setFilter] = useState(initialFilter)

  useEffect(() => {
    !!filter.field && setFilter(initialFilter)
  }, [data, setFilter])

  function sortData(field, reverse) {
    const copyDataArr = JSON.parse(JSON.stringify(data))
    const compareFnc = reverse ? reverseCompare : compare

    if (field === filter.field && filter.up === -1) {
      setFilter(initialFilter)
      return data
    }

    if (field === filter.field && filter.up === 1) {
      setFilter((prev) => ({ ...prev, up: -1 }))
      return copyDataArr.sort((v1, v2) => compareFnc(v2[field], v1[field]))
    }

    setFilter(() => ({ field, up: 1 }))
    return copyDataArr.sort((v1, v2) => compareFnc(v1[field], v2[field]))
  }

  return [filter, sortData]
}
