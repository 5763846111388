const boxRegex = /([A-Z]){1,3}\.\d\d?\.\d\d?\.?\d?/g

const matchBoxQuery = (value) => {
  const result = value.match(boxRegex)
  if (!result) return false
  return result[0] === value
}

export const parseQuery = (value) => {
  const match = matchBoxQuery(value)
  return match ? { box: value } : parseAddress(value)
}

const parseAddress = (query) => {
  const urlQuery = {}
  const keys = ['streetName', 'number', 'floor', 'door']
  query
    .split(',')
    .filter((data) => Boolean(data) && data.split('').some((e) => e !== ' '))
    .forEach((query, index) => {
      const trimmedString = query.trim()
      urlQuery[keys[index]] = trimmedString
    })

  return urlQuery
}
