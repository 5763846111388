import Icon from '@mdi/react'
import { mdiCircle } from '@mdi/js'
import Table from './Common/Table'

const statusMap = ['red', 'orange', 'green']

export default function UIsTable({
  data,
  handleCheckbox,
  handleAllCheckboxes,
  ids = []
}) {
  if (!data || !data.length) return null

  const tableData = data.map((ui) => ({
    selector: (
      <input
        type="checkbox"
        onChange={() => handleCheckbox(ui._id)}
        checked={ids.includes(ui._id)}
        name="id"
      />
    ),
    streetType: ui.streetType,
    streetName: ui.streetName,
    number: ui.number,
    letter: ui.letter,
    block: ui.block,
    ladder: ui.ladder,
    floor: ui.floor,
    door: ui.door,
    box: ui.box,
    status: (
      <Icon
        size={1}
        path={mdiCircle}
        color={statusMap[parseInt(ui.serviceStatus) - 1]}
      />
    ),
    key: ui._id
  }))

  const headers = [
    {
      header: (
        <input
          type="checkbox"
          onChange={handleAllCheckboxes}
          // checked={ids.includes(ui._id)}
          name="id"
        />
      )
    },
    { header: 'Tipo de vía' },
    { header: 'Nombre' },
    { header: 'Número' },
    { header: 'Letra' },
    { header: 'Bloque' },
    { header: 'Escalera' },
    { header: 'Piso' },
    { header: 'Puerta' },
    { header: 'Caja' },
    { header: 'Estado' }
  ]

  return <Table data={data} tableData={tableData} headers={headers} />
}
