import { Tabs, Tab } from 'react-bootstrap'

export default function DisplayTabs({ defaultActiveKey, tabs }) {
  return (
    <Tabs className="d-flex" {...{ defaultActiveKey }} unmountOnExit>
      {tabs.map((tab) => (
        <Tab
          key={tab.key}
          eventKey={tab.key}
          title={tab.title}
          className="my-5"
        >
          {tab.children}
        </Tab>
      ))}
    </Tabs>
  )
}
