import usePagination from 'hooks/usePagination'

import TableBody from './TableBody'
import TableHead from './TableHeader'

export default function Table({ data, tableData, headers, cb }) {
  const [currentRows, paginationBtns] = usePagination(data, tableData)

  const columnKeys = tableData.length ? Object.keys(tableData[0]) : []

  return (
    <>
      <div className="table-responsive">
        <table className="table table-hover">
          <TableHead {...{ columnKeys, data, headers, cb }} />
          <TableBody {...{ columnKeys }} data={currentRows} />
        </table>
      </div>
      {paginationBtns}
    </>
  )
}
