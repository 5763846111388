import { Form, Formik } from 'formik'
import { ButtonBlock } from 'styles/Buttons'
import { InputByfi } from 'styles-byfi'
import { uploadCSV } from 'api'

export default function UploadCSVForm() {
  return (
    <>
      <Formik initialValues={{ city: '', file: undefined }}>
        {({
          setFieldValue,
          values,
          isSubmitting,
          handleChange,
          setSubmitting,
          resetForm
        }) => {
          const { city, file } = values

          const handleFile = ({ target }) => {
            setFieldValue('file', target.files[0])
          }

          const handleSubmit = () => {
            setSubmitting(true)
            const formData = new FormData()
            formData.append('file', values.file)
            uploadCSV(city, formData)
              .then(() => resetForm())
              .finally(() => {
                setSubmitting(false)
              })
          }

          const allowSubmit = !isSubmitting && Boolean(city) && Boolean(file)

          return (
            <Form autoComplete="off" className="d-flex gap-5 align-items-start">
              <InputByfi
                type="text"
                value={city}
                label="Nombre de la ciudad"
                name="city"
                onChange={handleChange}
              />
              <InputByfi
                label="Archivo CSV"
                type="file"
                name="file"
                onChange={handleFile}
                accept="text/csv"
              />
              <div className="d-flex align-self-center">
                <ButtonBlock
                  success
                  type="button"
                  onClick={handleSubmit}
                  disabled={!allowSubmit}
                >
                  {isSubmitting ? 'Cargando...' : 'Subir archivo'}
                </ButtonBlock>
              </div>
            </Form>
          )
        }}
      </Formik>
    </>
  )
}
