import { getUIs } from 'api'
import { useState, useEffect } from 'react'
import { parseQuery } from 'utils/queries'

export default function useDebounceFetch(delayTime, inputValue) {
  const [data, setData] = useState([])
  const { city, query } = inputValue

  const determineAPICall = () => {
    if (!query) return

    const parsedQuery = { city, ...parseQuery(query) }
    const boxSearch = Boolean(parsedQuery.box)

    let delayedFetch

    if (!boxSearch && Object.keys(parsedQuery).length > 2) {
      delayedFetch = setTimeout(() => {
        getUIs(parsedQuery).then((data) => {
          setData(data)
        })
      }, delayTime / 2)
    }

    if (boxSearch) {
      delayedFetch = setTimeout(() => {
        getUIs(parsedQuery).then((data) => {
          setData(data)
        })
      }, delayTime)
    }

    return () => clearTimeout(delayedFetch)
  }

  useEffect(() => determineAPICall(), [query])

  return [data, setData]
}
