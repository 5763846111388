import Suggestions from './Suggestions'

import { InputByfi } from 'styles-byfi'
import { StyledSuggestionInput } from './StyledSuggestionInput'

export default function SuggestionInput({ setFieldValue, query, suggestions }) {
  const handleChange = ({ target }) => {
    setFieldValue('query', target.value)
  }

  const handleSelectSuggestion = (query) => {
    setFieldValue('query', query)
  }

  const getCommas = (str) => {
    let count = 0
    for (let i = 0; i < str.length; i++) {
      str[i] === ',' && count++
    }
    return count
  }

  return (
    <StyledSuggestionInput className="col-md-4">
      <InputByfi
        label="Búsqueda por caja o dirección. Formato: calle, num, piso, puerta"
        type="text"
        name="search"
        onChange={handleChange}
        value={query}
      />
      <div id="suggestions-container">
        <Suggestions
          inputValue={query}
          suggestions={suggestions}
          stopShow={getCommas(query || '') >= 1}
          cb={handleSelectSuggestion}
        />
      </div>
    </StyledSuggestionInput>
  )
}
