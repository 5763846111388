import useAuth from 'hooks/useAuth'
import { Form, Formik } from 'formik'
import * as Yup from 'yup'
import { ButtonByfi } from 'styles-byfi'

import TextInput from 'components/forms/inputs/TextInput'
import { loginAPI } from 'api'
import { useHistory } from 'react-router'

const initialValues = {
  dni: '',
  password: ''
}

export default function Login() {
  const { login } = useAuth()
  const history = useHistory()

  const handleSubmit = (values, { setErrors }) => {
    loginAPI(values).then((response) => {
      if (response.errorMessage) {
        setErrors({ dni: response.errorMessage })
        return
      }

      login(response)
      history.push('/')
    })
  }

  return (
    <div className="d-flex min-vh-100 justify-content-center align-items-center">
      <Formik
        initialValues={initialValues}
        onSubmit={handleSubmit}
        validationSchema={validationSchema()}
        validateOnChange={false}
      >
        {() => (
          <Form>
            <TextInput name="dni" label="DNI" />
            <TextInput name="password" label="Contraseña" type="password" />
            <ButtonByfi primary block className="mb-5" type="submit">
              Iniciar sesión
            </ButtonByfi>
          </Form>
        )}
      </Formik>
    </div>
  )
}

function validationSchema() {
  return Yup.object({
    dni: Yup.string().required('Obligatorio'),
    password: Yup.string().required('Obligatorio')
  })
}
