export default function Suggestions({ inputValue, suggestions, stopShow, cb }) {
  if (!inputValue || stopShow) return null

  return suggestions.map((elem) => (
    <div
      key={elem}
      onClick={() => {
        const query = `${elem},`
        cb(query)
      }}
    >
      <label htmlFor="">{elem}</label>
    </div>
  ))
}
