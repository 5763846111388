import { Form, Formik } from 'formik'

import { InputSelectByfi } from 'styles-byfi'

import { deleteUis } from 'api'
import useModal from 'hooks/useModal'
import useDebounceFetch from 'hooks/useDebounceFetch'
import useDebounceSuggestions from 'hooks/useDebounceSuggestiosn'

import UIsTable from 'components/UIsTable'
import UpdateUIsForm from './UpdateUIs'
import SuggestionInput from 'components/SuggestionInput'
import CustomModal from 'components/Common/Modal'
import { capitalize } from 'utils/capitalize'

export default function CoverageQueryForm() {
  const { actions, show } = useModal()
  return (
    <>
      <Formik initialValues={{ query: '', city: null, ids: [] }}>
        {({ setFieldValue, values, resetForm }) => {
          const { city, query, ids } = values

          const { handleShow, handleClose } = actions

          const [uis, setUis] = useDebounceFetch(800, { city, query })

          const suggestions = useDebounceSuggestions(
            500,
            `${city || ''}, ${query}`
          )

          const updateUIs = (ids, value) => {
            const updatedUIs = uis.map((ui) =>
              ids.includes(ui._id) ? { ...ui, ...value } : ui
            )
            setUis(updatedUIs)
            setFieldValue('ids', [])
          }

          const removeUIs = (ids) => {
            deleteUis(ids).then((res) => {
              if (!res.ok) return
              handleClose()()
              setUis(uis.filter((ui) => !ids.includes(ui._id)))
            })
            setFieldValue('ids', [])
          }

          const cityOptions = suggestions.map((city) => ({
            label: capitalize(city),
            value: city
          }))

          const handleSelect = (option) => setFieldValue('city', option.value)

          const sortedData = uis.sort(
            (a, b) =>
              parseInt(a.number) - parseInt(b.number) ||
              a.floor - b.floor ||
              a.door - b.door
          )

          const handleCheckbox = (id) => {
            const newIds = ids.includes(id)
              ? ids.filter((e) => e !== id)
              : [...ids, id]
            setFieldValue('ids', newIds)
          }

          const handleAllCheckboxes = ({ target }) => {
            target.checked
              ? setFieldValue(
                  'ids',
                  uis.map((e) => e._id)
                )
              : setFieldValue('ids', [])
          }

          return (
            <>
              <Form
                className="d-flex gap-5 align-items-start"
                autoComplete="off"
              >
                <InputSelectByfi
                  options={cityOptions}
                  name="city"
                  onChange={handleSelect}
                  value={city}
                  col="col-md-4"
                  label="Selecciona una ciudad"
                />
                <SuggestionInput
                  {...{
                    query,
                    suggestions,
                    setFieldValue
                  }}
                />
              </Form>
              <UpdateUIsForm {...{ ids, updateUIs, removeUIs: handleShow }} />
              <UIsTable
                data={sortedData}
                {...{ handleCheckbox, handleAllCheckboxes, ids }}
              />
              <CustomModal
                show={show}
                handleClose={handleClose()}
                submitAction={() => removeUIs(ids)}
              >
                <h1 className="text-secondary text-center">
                  Vas a eliminar {ids.length} UIs. ¿Estás seguro?
                </h1>
              </CustomModal>
            </>
          )
        }}
      </Formik>
    </>
  )
}
