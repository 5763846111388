import jwtDecode from 'jwt-decode'

import { TOKEN } from 'utils/constants'

export default function (state, action) {
  switch (action.type) {
    case 'login': {
      const decoded = jwtDecode(action.payload)
      localStorage.setItem(TOKEN, `Bearer ${action.payload}`)
      return {
        auth: { ...decoded },
        loading: false
      }
    }
    case 'logout': {
      localStorage.removeItem(TOKEN)
      return { auth: undefined, loading: false }
    }
    case 'load': {
      if (action.payload) {
        return { auth: { ...jwtDecode(action.payload) }, loading: false }
      }
      return { ...state, loading: false }
    }
    default:
      return state
  }
}
