import { Form, Formik } from 'formik'

import CustomModal from 'components/Common/Modal'
import useModal from 'hooks/useModal'
import useDebounceSuggestions from 'hooks/useDebounceSuggestiosn'
import { deleteCityUIs } from 'api'
import { capitalize } from 'utils/capitalize'

import { InputSelectByfi } from 'styles-byfi'
import { ButtonBlock } from 'styles/Buttons'

export default function DeleteCityUIs() {
  return (
    <Formik initialValues={{ city: '' }}>
      {({ values, setFieldValue, isSubmitting }) => {
        const { city } = values
        const suggestions = useDebounceSuggestions(500, city)
        const cityOptions = suggestions.map((city) => ({
          label: capitalize(city),
          value: city
        }))

        const { actions, show } = useModal()
        const { handleShow, handleClose } = actions

        const handleSelect = (option) => setFieldValue('city', option.value)

        const handleSubmit = () => {
          deleteCityUIs(values.city).then(() => setFieldValue('city', ''))
          handleClose()()
        }

        return (
          <Form autoComplete="off" className="d-flex gap-5 align-items-start">
            <InputSelectByfi
              key={city}
              options={cityOptions}
              name="city"
              onChange={handleSelect}
              value={city}
              col="col-md-4"
              label="Selecciona una ciudad"
            />
            <div className="d-flex align-self-center">
              <ButtonBlock
                danger
                type="button"
                onClick={handleShow}
                disabled={!city}
              >
                {isSubmitting ? 'Eliminando...' : 'Eliminar UIs'}
              </ButtonBlock>
            </div>
            <CustomModal
              show={show}
              handleClose={handleClose()}
              submitAction={handleSubmit}
            >
              <h1 className="text-secondary text-center">
                Vas a eliminar todas las UIs de {city}. ¿Estás seguro?
              </h1>
            </CustomModal>
          </Form>
        )
      }}
    </Formik>
  )
}
