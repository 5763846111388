export const ThemeByfi = {
  colors: {
    negro: '#333333',
    gris_muy_oscuro: '#4F4F4F',
    gris_oscuro: '#9e9eaf',
    gris_medio: '#BDBDBD',
    gris_claro: '#e0e2ec',
    gris_muy_claro: '#F2F2F2',
    blanco: '#FFFFFF',
    texto_claro: '#9e9eaf',
    texto_oscuro: '#8181B4',
    lila_claro: '#603BE2',
    lila: '#4911C1',
    lila_oscuro: '#380FA8',
    lila_muy_oscuro: '#29129B',

    azul_claro: '#50E2D4',
    azul: '#27D6C9',
    azul_oscuro: '#0CC6B4',

    amarillo_super_claro: '#fcf3d0',
    amarillo_claro: '#FFF4C5',
    amarillo: '#FFEA92',
    amarillo_oscuro: '#FFDC4A',
    amarillo_muy_oscuro: '#F6A847',
    amarillo_gris: '#c1a179',

    rojo_claro: '#FF9283',
    rojo: '#FF6666',
    rojo_oscuro: '#FF443A'
  },
  fonts: {
    archivo: "'Archivo', sans-serif",
    lato: "'Lato', sans-serif",
    label: `
           font-weight: 500;
           font-size: 12px;
           letter-spacing: 0.5px;
           text-transform: uppercase;
           `
  }
}
