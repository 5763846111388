import { createContext, useEffect, useMemo, useReducer } from 'react'
import { useHistory } from 'react-router-dom'

import { TOKEN } from 'utils/constants'
import reducer from './reducers/Auth.reducers'

export const AuthContext = createContext({})

const initialState = {
  auth: undefined,
  loading: true
}

export default function AuthContextProvider({ children }) {
  const history = useHistory()
  const [state, dispatch] = useReducer(reducer, initialState)

  const { auth, loading } = state

  useEffect(() => {
    const token = localStorage.getItem(TOKEN)
    load(token)
  }, [])

  const load = (token) => dispatch({ type: 'load', payload: token })

  const login = (token) => {
    dispatch({ type: 'login', payload: token })
    history.push('/')
  }

  const logout = () => {
    dispatch({ type: 'logout' })
  }

  const authData = useMemo(
    () => ({
      auth,
      loading,
      login,
      logout
    }),
    [state]
  )

  return (
    <AuthContext.Provider value={authData}>{children}</AuthContext.Provider>
  )
}
