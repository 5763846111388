import DisplayTabs from 'components/Common/DisplayTabs'
import CoverageQueryForm from 'components/forms/CoverageQueryForm'
import DeleteCityUIs from 'components/forms/DeleteCityUIs'
import UploadCSVForm from 'components/forms/UploadCSVForm'

export default function Dashboard() {
  const tabs = [
    {
      key: 'Cobertura',
      title: 'Cobertura',
      children: <CoverageQueryForm />
    },
    {
      key: 'Cargar CSV',
      title: 'Cargar CSV',
      children: <UploadCSVForm />
    },
    {
      key: 'Gestionar datos',
      title: 'Gestionar datos',
      children: <DeleteCityUIs />
    }
  ]

  return (
    <div className="container pt-5 min-vh-50">
      <DisplayTabs defaultActiveKey={tabs[0].key} tabs={tabs} />
    </div>
  )
}
