export default function TableBody({ columnKeys, data }) {
  const tableAccessors = columnKeys.slice(0, -1)

  const tableBodyRows = data.map((row, i) => (
    <tr key={i}>
      {tableAccessors.map((selector, idx) => (
        <td
          className={`${row[selector]?.className || ''} ${
            selector === 'streetName' ? 'capitalize' : ''
          } align-middle`}
          key={selector + idx}
        >
          {row[selector]?.value ?? row[selector]}
        </td>
      ))}
    </tr>
  ))

  return <tbody>{tableBodyRows}</tbody>
}
