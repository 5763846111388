export const TOKEN = '@SistelProvisionToken'

export const BASE_PATH =
  process.env.NODE_ENV === 'development'
    ? 'http://localhost:5000/api'
    : process.env.REACT_APP_API_URL

// SERVICE STATUS
export const SIN_SERVICIO = 'SIN SERVICIO'
export const PENDIENTE_INSTALAR = 'PENDIENTE INSTALAR'
export const ACTIVO = 'ACTIVO'
export const SERVICE_STATUS = [SIN_SERVICIO, PENDIENTE_INSTALAR, ACTIVO]

// UIS FIELDS
export const CITY = 'city'
export const STREET_TYPE = 'streetType'
export const STREET_NAME = 'streetName'
export const NUMBER = 'number'
export const FLOOR = 'floor'
export const BLOCK = 'block'
export const LADDER = 'ladder'
export const LETTER = 'letter'
export const DOOR = 'door'
export const BOX = 'box'
export const SERVICE_STATUS_FIELD = 'serviceStatus'
export const UI_FIELDS = [
  CITY,
  STREET_TYPE,
  STREET_NAME,
  NUMBER,
  FLOOR,
  BLOCK,
  LADDER,
  LETTER,
  DOOR,
  BOX,
  SERVICE_STATUS_FIELD
]

export const translateField = (field) => {
  const fieldHashmap = {
    [CITY]: 'Ciudad',
    [STREET_TYPE]: 'Tipo de dirección',
    [STREET_NAME]: 'Dirección',
    [NUMBER]: 'Número',
    [FLOOR]: 'Piso',
    [BLOCK]: 'Bloque',
    [LADDER]: 'Escalera',
    [LETTER]: 'Letra',
    [DOOR]: 'Puerta',
    [BOX]: 'Caja',
    [SERVICE_STATUS_FIELD]: 'Estado de servicio'
  }

  return fieldHashmap[field] || ''
}
