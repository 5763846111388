import styled from 'styled-components'

export const StyledSuggestionInput = styled.div`
  position: relative;
  display: inline-block;
  width: 450px;
  color: #5c5c8e;

  #suggestions-container {
    max-height: 400px;
    width: 100%;
    overflow-y: scroll;
    ::-webkit-scrollbar {
      width: 13px;
      height: 13px;
    }
    ::-webkit-scrollbar-thumb {
      background: #c2c2c2;
      border-radius: 10px;
    }
    ::-webkit-scrollbar-thumb:hover {
      background: #b1b2b5;
    }
    ::-webkit-scrollbar-track {
      background: #ffffff;
      border-radius: 10px;
      box-shadow: inset 7px 10px 12px 0px #f0f0f0;
    }
  }

  #suggestions-container div {
    padding: 10px;
    border-radius: 10px;
    :hover {
      background-color: rgba(112, 161, 255, 0.1);
      cursor: pointer;
    }
  }

  input {
    border: 1px solid transparent;
    padding: 10px;
    font-size: 16px;
    background-color: #f1f1f1;
    width: 100%;
  }
`
