import { getSuggestions } from 'api'
import { useState, useEffect } from 'react'

export default function useDebounceSuggestions(delayTime, inputValue) {
  const [suggestions, setSuggestions] = useState([])

  useEffect(() => {
    if (inputValue) {
      const delayedFetch = setTimeout(() => {
        getSuggestions(inputValue).then((data) => {
          setSuggestions(data)
        })
      }, delayTime)
      return () => clearTimeout(delayedFetch)
    }

    getSuggestions().then((data) => {
      setSuggestions(data)
    })
  }, [inputValue])

  return suggestions
}
