import React from 'react'
import ReactDOM from 'react-dom'
import { BrowserRouter } from 'react-router-dom'
import { ToastContainer } from 'react-toastify'

import { ThemeProvider } from 'styled-components'
import { ThemeByfi } from 'styles-byfi/src/theme'
import { MainStyles } from 'styles/MainStyles'

import AuthContextProvider from 'context/AuthContext'
import App from './App'

import 'bootstrap/dist/css/bootstrap.min.css'
import 'react-toastify/dist/ReactToastify.css'

ReactDOM.render(
  <React.StrictMode>
    <BrowserRouter>
      <AuthContextProvider>
        <ThemeProvider theme={ThemeByfi}>
          <MainStyles>
            <App />
            <ToastContainer autoClose={2500} />
          </MainStyles>
        </ThemeProvider>
      </AuthContextProvider>
    </BrowserRouter>
  </React.StrictMode>,
  document.getElementById('root')
)
