import { useState } from 'react'

/**
 * @function handleShow Receives modal title
 * @function handleClose Receives callback (setState)
 * @returns {{actions: {handleShow: function, handleClose: function}, show: boolean, title: string}}
 */

export default function useModal() {
  const [show, setShow] = useState(false)
  const [title, setTitle] = useState('')

  const handleClose = (cb) => () => {
    cb && cb(undefined)
    setShow(false)
  }

  const handleShow = (title) => {
    setTitle(title)
    setShow(true)
  }

  const onClose = () => {
    setShow(false)
  }

  const actions = { handleShow, handleClose, onClose }

  return { actions, show, title }
}
