import { toast } from 'react-toastify'
import { BASE_PATH, TOKEN } from 'utils/constants'

export const loginAPI = async (values) => {
  const options = {
    headers: {
      'Content-Type': 'application/json'
    },
    method: 'POST',
    body: JSON.stringify(values)
  }

  const url = `${BASE_PATH}/users/login`

  try {
    const response = await fetch(url, options)
    const data = await response.json()

    return data
  } catch (error) {
    console.error(error)
    return error
  }
}

export const getUIs = async (query) => {
  const options = {
    headers: {
      'Content-Type': 'application/json',
      token: localStorage.getItem(TOKEN)
    },
    method: 'GET'
  }

  const url = new URL(`${BASE_PATH}/uis`)

  Object.entries(query).forEach(([key, value]) => {
    if (!value) return
    url.searchParams.append(key, value)
  })

  try {
    const response = await fetch(url, options)
    const data = await response.json()

    return data
  } catch (error) {
    console.error(error)
  }
}

export const getSuggestions = async (query) => {
  const options = {
    headers: {
      'Content-Type': 'application/json',
      token: localStorage.getItem(TOKEN)
    },
    method: 'GET'
  }

  const url = new URL(`${BASE_PATH}/uis/suggestions`)

  const queryKeys = ['city', 'streetName']

  query &&
    query
      .split(',')
      .filter((data) => Boolean(data) && data.split('').some((e) => e !== ' '))
      .forEach((query, index) => {
        const trimmedString = query.trim()
        url.searchParams.append(queryKeys[index], trimmedString)
      })

  try {
    const response = await fetch(url, options)
    const data = await response.json()

    return data
  } catch (error) {
    console.error(error)
  }
}

export const getSuggestionsV2 = async (query) => {
  const options = {
    headers: {
      'Content-Type': 'application/json',
      token: localStorage.getItem(TOKEN)
    },
    method: 'GET'
  }

  const url = new URL(`${BASE_PATH}/uis/suggestionsv2`)

  const queryEntries = Object.entries(query)

  queryEntries.forEach(([key, value]) => {
    if (!value) return
    url.searchParams.append(key, value)
  })

  try {
    const response = await fetch(url, options)
    const data = await response.json()

    return data
  } catch (error) {
    console.error(error)
  }
}

export const updateUI = async (query, body) => {
  const options = {
    headers: {
      'Content-Type': 'application/json',
      token: localStorage.getItem(TOKEN)
    },
    body: JSON.stringify(body),
    method: 'PUT'
  }

  const url = new URL(`${BASE_PATH}/uis`)

  const queryEntries = Object.entries(query)

  queryEntries.forEach(([key, value]) => {
    if (!value) return
    url.searchParams.append(key, value)
  })

  try {
    const response = await fetch(url, options)
    toast.success('UIs actualizadas.')
    return response
  } catch (error) {
    console.error(error)
  }
}

export const updateUIsFromIDs = async (body) => {
  const options = {
    headers: {
      'Content-Type': 'application/json',
      token: localStorage.getItem(TOKEN)
    },
    body: JSON.stringify(body),
    method: 'PUT'
  }

  const url = new URL(`${BASE_PATH}/uis/ids`)

  try {
    const response = await fetch(url, options)
    toast.success('UIs actualizadas.')
    return response
  } catch (error) {
    console.error(error)
  }
}

export const deleteUis = async (body) => {
  const options = {
    headers: {
      'Content-Type': 'application/json',
      token: localStorage.getItem(TOKEN)
    },
    body: JSON.stringify(body),
    method: 'DELETE'
  }

  const url = new URL(`${BASE_PATH}/uis`)

  try {
    const response = await fetch(url, options)
    toast.success('UIs eliminadas')
    return response
  } catch (error) {
    console.error(error)
  }
}

export const deleteCityUIs = async (city) => {
  const options = {
    headers: {
      'Content-Type': 'application/json',
      token: localStorage.getItem(TOKEN)
    },
    method: 'DELETE'
  }

  const url = new URL(`${BASE_PATH}/uis/${city}`)

  try {
    const response = await fetch(url, options)
    toast.success('UIs eliminadas')
    return response
  } catch (error) {
    console.error(error)
  }
}

export const uploadCSV = async (city, formData) => {
  const options = {
    headers: {
      token: localStorage.getItem(TOKEN)
    },
    body: formData,
    method: 'POST'
  }

  const url = new URL(`${BASE_PATH}/uis`)

  url.searchParams.append('city', city)

  try {
    const response = await fetch(url, options)
    toast.success('CSV cargado correctamente.')
    return response
  } catch (error) {
    console.error(error)
  }
}
