import useAuth from 'hooks/useAuth'
import Dashboard from 'pages/Dashboard'
import Login from 'pages/Login'
import { Route, Switch } from 'react-router'

export default function App() {
  const { auth, loading } = useAuth()

  if (loading) return <h1>loading</h1>

  if (!auth) return <Login />

  return (
    <div className="main-container">
      <Switch>
        <Route path="/" component={Dashboard} />
      </Switch>
    </div>
  )
}
