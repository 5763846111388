import { Form, Formik } from 'formik'

import { SERVICE_STATUS, translateField, UI_FIELDS } from 'utils/constants'

import { ButtonBlock } from 'styles/Buttons'
import { InputByfi, InputSelectByfi } from 'styles-byfi'
import { updateUIsFromIDs } from 'api'

export default function UpdateUIsForm({ ids, updateUIs, removeUIs }) {
  return (
    <>
      <h4>Editar / Eliminar {ids.length} UUIIs</h4>
      <Formik initialValues={{ field: '', newValue: '' }}>
        {({
          values,
          handleChange,
          setFieldValue,
          isSubmitting,
          setSubmitting
        }) => {
          const { field, newValue } = values
          const handleSelect = (name) => (option) =>
            setFieldValue(name, option.value)

          const formatOptions = (arr) => {
            if (!arr || !arr.length) return []
            return arr.map((e) => ({ label: translateField(e), value: e }))
          }

          const handleUpdate = () => {
            setSubmitting(true)
            const body = { ids, [field]: newValue }
            updateUIsFromIDs(body)
              .then((res) => {
                if (!res.ok) return
                updateUIs(ids, { [field]: newValue })
              })
              .finally(() => setSubmitting(false))
          }

          const handleDelete = () => {
            setSubmitting(true)
            removeUIs(ids)
            setSubmitting(false)
          }

          const allowUpdate =
            !isSubmitting && Boolean(field) && Boolean(newValue)

          return (
            <Form className="d-flex gap-5 align-items-start" autoComplete="off">
              <InputSelectByfi
                options={formatOptions(UI_FIELDS)}
                name="field"
                onChange={handleSelect('field')}
                value={field}
                label="Selecciona un campo"
                isDisabled={!ids.length}
                col="col-md-2"
              />
              <NewValueInput
                {...{ field, handleChange }}
                handleSelect={handleSelect('newValue')}
              />
              <div className="d-flex align-self-center">
                <ButtonBlock
                  success
                  type="button"
                  onClick={handleUpdate}
                  disabled={!allowUpdate}
                >
                  {isSubmitting ? 'Actualizando...' : 'Actualizar'}
                </ButtonBlock>
                <ButtonBlock
                  danger
                  type="button"
                  onClick={handleDelete}
                  className="mx-2"
                  disabled={isSubmitting || ids.length === 0}
                >
                  {isSubmitting ? 'Eliminando...' : 'Eliminar'}
                </ButtonBlock>
              </div>
            </Form>
          )
        }}
      </Formik>
    </>
  )
}

function NewValueInput({ field, handleSelect, value, handleChange }) {
  if (field === 'serviceStatus') {
    const serviceStatusOptions = SERVICE_STATUS.map((e, i) => ({
      label: e,
      value: i + 1
    }))

    return (
      <InputSelectByfi
        options={serviceStatusOptions}
        name="serviceStatus"
        onChange={handleSelect}
        value={value}
        label="Estado de servicio"
        col="col-md-2"
      />
    )
  }

  return (
    <InputByfi
      name="newValue"
      onChange={handleChange}
      value={value}
      label="Nuevo valor"
      disabled={!field}
      col="col-md-2"
    />
  )
}
